ul.form-collection {
  list-style: none;
  padding: 0;

  li {
    padding: 10px;
    background: #f2f2f2;
    border-radius: 5px;
    margin-bottom: 10px;
  }
}

ul.pagination {
  padding: 0 0 0 15px;
}

.submenu {
  padding: 0 0 0 15px !important;
  margin-left: 60px !important;
}